.root{
    position: fixed;
    background-color: #0000009f;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    animation: show_root 0.3s ease-out;
    animation-fill-mode: both;
}
.content{
    animation-fill-mode: both;
    position: relative;
    width: 500px;
    height: 400px;
    background-color: var(--body-background);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    padding: 20px;
    box-sizing: border-box;
    animation: show_content 0.3s ease-out;
    & h1{
        font-size: 22px;
        color: var(--text-color-strong);
        margin: 0px;
        font-family: 'Manrope';
        font-weight: 800;
    }
    & h2{
        font-size: 14px;
        color: var(--text-color);
        margin: 0px;
        font-family: 'Manrope';
        font-weight: 500;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

.addresses_panel{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;
    gap: 5px;
}

.button_add{
    z-index: 3;
    position: absolute;
    background-color: var(--app-accent-color);
    font-size: 35px;
    right: 15px;
    top: 15px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    box-shadow: 1px 1px 7px 3px #0000002d;
    color: #FFF;
    font-family: 'Manrope';
    font-weight: 300;
    border-radius: 35px;
    outline: none;
    border: none;
    align-self: flex-end;
    transition: .3s;
    outline: 2px transparent solid !important;
    outline-offset: 2px !important;
    &:hover:enabled{
        outline: 2px #80808077 solid !important;
        outline-offset: 2px !important;
    }
    &:focus{
        outline: 2px #80808077 solid !important;
        outline-offset: 2px !important;
    }
}

.center_panel{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
    margin: 20px 0px;
}

.bottom_panel{
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.button_accept{
    background-color: var(--app-accent-color);
    font-size: 14px;
    color: #FFF;
    font-family: 'Manrope';
    font-weight: 600;
    user-select: none;
    border-radius: 35px;
    outline: none;
    border: none;
    align-self: center;
    padding: 8px 15px;
    transition: .3s;
    outline: 2px transparent solid !important;
    outline-offset: 2px !important;
    &:hover:enabled{
        outline: 2px #80808077 solid !important;
        outline-offset: 2px !important;
    }
    &:focus{
        outline: 2px #80808077 solid !important;
        outline-offset: 2px !important;
    }
}



.button_close{
    background-color: transparent;
    font-size: 14px;
    user-select: none;
    color: var(--text-color);
    font-family: 'Manrope';
    font-weight: 600;
    border-radius: 35px;
    outline: none;
    border: none;
    align-self: center;
    padding: 8px 15px;
    transition: .3s;
    outline: 2px transparent solid !important;
    outline-offset: 2px !important;
    border: 2px #80808077 solid ;
    &:hover:enabled{
        outline: 2px #80808077 solid !important;
        outline-offset: 2px !important;
    }
    &:focus{
        outline: 2px #80808077 solid !important;
        outline-offset: 2px !important;
    }
}


@keyframes show_root {
    from{
        backdrop-filter: blur(0px);
        background-color: transparent;
    } 
    to{
        backdrop-filter: blur(5px);
        background-color: #0000009f;
    } 
}

@keyframes hide_root {
    from{
        backdrop-filter: blur(5px);
        background-color: #0000009f;
    } 
    to{
        backdrop-filter: blur(0px);
        background-color: transparent;
    } 
}

@keyframes show_content {
    from{
        transform: scale(0.8);
        opacity: 0;
    } 
    to{
        transform: scale(1);
        opacity: 1;
    } 
}

@keyframes hide_content {
    from{
        transform: scale(1);
        opacity: 1;
    } 
    to{
        transform: scale(0.8);
        opacity: 0;
    } 
}

.radio_button_root{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}
.address_remove_button{
    height: 12px;
    opacity: 0.4;
}

.radio_button{
    position: relative;
    flex: 1;
    margin: 5px 5px;
    height: auto;
    min-height: 45px;
    display: flex;
    align-items: center;
    input,div{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    label{
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    div{
        z-index: 1;
        outline: 2px transparent solid !important;
        outline-offset: 2px !important;
        background-color: transparent;
        border-radius: 35px;
        user-select: none;
        border: 2px solid #5e5e5e27;
        display: flex;
        transition: 0.3s;
    }


    
    input{
        opacity: 0;
        z-index: 4;
        &:hover:enabled ~ div{
            outline: 2px #80808077 solid !important;
            outline-offset: 2px !important;
        }
        &:focus ~ div{ 
            outline: 2px #80808077 solid !important;
            outline-offset: 2px !important;
        }
        &:checked ~ div{
            background-color: var(--app-accent-color);
        }
        &:checked + label{
            color:#FFF;
        }
        &:not(:checked) ~ img{
            opacity: 0;
        }
        
    }
    
    img{
        z-index: 2;
        position: absolute;
        box-sizing: border-box;
        bottom: 0px;
        height: 100%;
        padding: 12px;
        padding-left: 14px;
        object-fit:scale-down;
        transition: 0.3s;
    }
    label{
        z-index: 3;
        padding-left: 10px;
        align-self: flex-end;
        transition: .3s;
        padding-left: 40px;
        padding-right: 10px;
        font-size: 16px;
        color: var(--text-color);
        font-family: 'Manrope';
        font-weight: 500;
        
        max-lines: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        align-items: center;
    }
}