.loading_message{
    display: flex;
    color: var(--text-color);
    width: 100%;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-weight: 600;
    font-size: 25px;
}

.root{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: var(--app-desktop-max-width);
    margin:  0 auto;
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    margin-bottom: 50px;
}
.head{
    z-index: 2;
    background-color: var(--body-background);
    position: sticky;
    width: 100%;
    top: 0px;
    max-width: 1224px;
    transition: 0.3s;
    h1{
        color: var(--text-color-strong);
        padding: 8px 0px 8px 45px;
        font-size: 25px;        
        margin: 0px;
        
        
    }
}

.main_panel{
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 20px;
    max-width: 1224px;
    width: 100%;
    h2{
        text-transform: uppercase;
        color: var(--text-color);
        font-size: 18px;       
        font-weight: 700; 
        margin-left: 20px;
        padding: 5px 8px;
        display: flex;
        align-items: center;
        // border: 2px  var(--text-color-strong) solid;
        margin-bottom: 30px;
    }
}

.client_info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    flex: 1;
    
    form{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
        // background-color: var(--d-product-bg-color);
        border-radius: 8px;
        margin: 8px;
        gap: 15px;
        box-sizing: border-box;
    }
}

.products_list{
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    flex: 1;
    box-sizing: border-box;
}

.payment_panel{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 45px;
    
    .payment_selector{
        display: flex;
        flex-direction: row;
        gap: 15px;
        padding: 20px;
        justify-content: center;
    }
    .pt_button{
        position: relative;
        width: 125px;
        height: 40px;
        input,label{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        input{
            opacity: 0;
            z-index: 2;
            &:hover:enabled + label{
                outline: 2px #80808077 solid !important;
                outline-offset: 2px !important;
            }
            &:focus + label{ 
                outline: 2px #80808077 solid !important;
                outline-offset: 2px !important;
            }
            &:checked + label{
                background-color: var(--d-radio-enabled-bg-color);
                color: var(--d-radio-enabled-text-color);
                font-weight: 700;
                border: none;
            }
        }
        label{
            background-color: transparent;
            outline: none;
            border: none;
            align-self: flex-end;
            transition: .3s;
            outline: 2px transparent solid !important;
            outline-offset: 2px !important;
            font-size: 16px;
            color: var(--text-color);
            font-family: 'Manrope';
            font-weight: 500;
            border-radius: 35px;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #5252525d;
        }
        
        
        
    }
}


.input_simple {
    position: relative;
    input{
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus {
            background-color: transparent;
            -webkit-text-fill-color: #ffffff;
            -webkit-box-shadow: 0 0 0px 40rem rgb(29, 29, 29) inset;
        }
        
        margin-top: 3px;
        padding: 5px 3px;
        width: 100%;
        background: transparent;
        border: none;
        font-size: 16px;
        box-sizing: border-box;
        color: var(--text-color);
        outline: none;
        display: flex;
        font-family: 'Manrope';
        align-items: center;
        font-weight: 700;
        transition: 0.3s;
        border-bottom: 2px var(--text-color-placeholder) solid;
    }
    label{
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;    
        display: flex;
        align-items: center;
        color: var(--text-color-placeholder);
        font-size: 16px;
        font-family: 'Manrope';
        font-weight: 600;
        cursor: text;
        user-select: none;
        transition: 0.2s ease-out;
        min-width: 0;
        max-width: calc(100% - 20px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    input:focus + label,
    input:not(:placeholder-shown) + label{
        color: var(--app-accent-color);
        font-size: 10px;
        top: -8px;
        left: 3px;
        height: 15px;
        font-weight: 800;
    }
    input::placeholder{
        color: transparent;
        user-select: none;
    }
    input:focus{
        border-bottom: 2px var(--app-accent-color) solid;
    }
}






.input_phone {
    display: flex;
    flex-direction: row;
    width: 125px;
    position: relative;
    
    input{
        width: 100%;
        box-sizing: border-box;
        margin-top: 3px;
        flex: 1;
        padding-left: 18px;
        padding-top: 5px;
        padding-bottom: 5px;
        background: transparent;
        border: none;
        font-size: 16px;
        color: var(--text-color);
        outline: none;
        display: flex;
        font-family: 'Manrope';
        align-items: center;
        font-weight: 700;
        transition: 0.3s;
        border-bottom: 2px var(--text-color-placeholder) solid;
        &:focus + label, 
        &:not(:placeholder-shown) + label{
            color: var(--app-accent-color);
            font-size: 10px;
            top: -8px;
            left: 3px;
            height: 15px;
            font-weight: 800;
        }
        &::placeholder{
            opacity: 0;
        }
        &:focus{
            border-bottom: 2px var(--app-accent-color) solid;
        }
        &:valid ~ p, 
        &:focus ~ p{
            opacity: 1;
            visibility: visible;
        }
    }
    
    label{
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;    
        display: flex;
        align-items: center;
        color: var(--text-color-placeholder);
        font-size: 16px;
        font-family: 'Manrope';
        font-weight: 600;
        cursor: text;
        user-select: none;
        transition: 0.2s ease-out;
        min-width: 0;
        max-width: calc(100% - 20px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    p{
        padding-top: 1px;
        content: "+7";
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        color: var(--text-color);
        display: flex;
        align-items: center;
        opacity: 0;
        font-family: 'Manrope';
        font-weight: 700;
        transition: 0.3s;
        visibility: collapse;
        pointer-events: none;
    }
    
}

.expedition_type_panel 
{
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 20px;
    justify-content: center;
}



.et_button{
    position: relative;
    width: 125px;
    height: 40px;
    input,label{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    input{
        opacity: 0;
        z-index: 2;
        &:hover:enabled + label{
            outline: 2px #80808077 solid !important;
            outline-offset: 2px !important;
        }
        &:focus + label{ 
            outline: 2px #80808077 solid !important;
            outline-offset: 2px !important;
        }
        &:checked + label{
            background-color: var(--d-radio-enabled-bg-color);
            color: var(--d-radio-enabled-text-color);
            border: none;
            font-weight: 700;
        }
    }
    label{
        background-color: transparent;
        outline: none;
        border: none;
        align-self: flex-end;
        transition: .3s;
        outline: 2px transparent solid !important;
        outline-offset: 2px !important;
        font-size: 16px;
        color: var(--text-color);
        font-family: 'Manrope';
        font-weight: 500;
        border-radius: 35px;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #5252525d;
    }
    
    
    
}

.delivery_address{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    padding: 10px 20px;
    
}


.button_address{
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 40px;
    align-items: center;
    background-color: transparent;
    font-size: 16px;
    color: var(--text-color);
    font-family: 'Manrope';
    font-weight: 600;
    user-select: none;
    border-radius: 35px;
    outline: none;
    border: none;
    align-self: center;
    padding: 8px 15px;
    border: 2px solid #5252525d;
    transition: .3s;
    outline: 2px transparent solid !important;
    outline-offset: 2px !important;
    img{
        height: 20px;
    }
    &:hover:enabled{
        outline: 2px #80808077 solid !important;
        outline-offset: 2px !important;
    }
    &:focus{
        outline: 2px #80808077 solid !important;
        outline-offset: 2px !important;
    }
}


.button_address_filled{
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 40px;
    align-items: center;
    background-color: var(--d-radio-enabled-bg-color);
    color: var(--d-radio-enabled-text-color);
    font-size: 16px;
    
    font-family: 'Manrope';
    font-weight: 700;
    user-select: none;
    border-radius: 35px;
    outline: none;
    border: none;
    align-self: center;
    padding: 8px 15px;
    transition: .3s;
    outline: 2px transparent solid !important;
    outline-offset: 2px !important;
    img{
        height: 25px;
    }
    &:hover:enabled{
        outline: 2px #80808077 solid !important;
        outline-offset: 2px !important;
    }
    &:focus{
        outline: 2px #80808077 solid !important;
        outline-offset: 2px !important;
    }
}




@media (max-width: 800px){
    .main_panel{
        flex-direction: column;
    }
    .main_panel{
        align-items: stretch;
        width: 100%;
    }
}




