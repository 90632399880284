

.root{
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 130;
    display: flex;
    width: 100lvw;
    height: 100lvh;
    ;
    animation: bg_show 300ms ;
    animation-fill-mode: forwards;
}
.background{
    
}
.content{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    background-color: var(--d-product-bg-color);
    border-radius: calc(var(--product-border-radius) + 1px );
}

.image_thmb{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    /* border-radius: calc(var(--product-border-radius) ); */
}
.image_thmb img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation-fill-mode: forwards;
    
    border-radius: calc(var(--product-border-radius) -2px);
}
.ui_controls{
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    /* opacity: 0; */
    animation: show_ui_controls 300ms ease-out backwards;
    animation-delay: 300ms;
}
.close{
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    outline: none;
    width: 35px;
    height: 35px;
    padding: 8px;
    background-color: transparent;
    user-select: none;
    transition: .3s;
}
.close img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.main_gradient{
    position: absolute;
}
.info_panel{
    z-index: 10;
    min-height: 250px;
    position: absolute; 
    padding: 10px;
}
.info_panel h1{
    color: #FFF;
    font-size: 22px;
    line-height: 110%;
    font-weight: 800;
    font-family: 'Manrope';
}
.info_panel h2{
    margin: 5px;
    color: #FFF;
    font-size: 36px;
    line-height: 110%;
    font-weight: 800;
    font-family: 'Manrope';
}
.price_buttons_panel span{
    user-select: none;
    font-size: 0.8em;
    opacity: 0.8;
    font-weight: 400;
}
.additional_info{
    display: flex;
    margin-left: 5px;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    position: relative;
}
.additional_info img{
    user-select: none;
    height: 13px;
}

.energy_value::before {
    opacity: 0;
    content: "Энергетическая ценность";
    z-index: 2;
    font-size: 13px;
    top: 35px;
    visibility: hidden;
    padding: 8px 10px;
    border-radius: 8px;
    font-family: 'Manrope';
    color: #FFF;
    font-weight: 600;
    background-color: #000000d8;
    position: absolute;
    box-shadow: 0px 0px 4px 1px #0000003d;
    transition: 0.3s;
    transform: scale(0.9);
}
.weight::before {
    opacity: 0;
    content: "Выход блюда в граммах";
    z-index: 2;
    font-size: 13px;
    visibility: hidden;
    top: 35px;
    padding: 8px 10px;
    border-radius: 8px;
    font-family: 'Manrope';
    color: #FFF;
    font-weight: 600;
    background-color: #000000d8;
    position: absolute;
    box-shadow: 0px 0px 4px 1px #0000003d;
    transition: 0.3s;
    transform: scale(0.9);
}
.weight:hover::before{
    opacity: 1;
    visibility: visible;
    top: 28px;
    transform: scale(1);
}
.energy_value:hover::before{
    opacity: 1;
    visibility: visible;
    top: 28px;
    transform: scale(1);
}

.additional_info p{
    margin: 5px 0px;
}
.additional_info span{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}



.info_panel p{
    color: #FFF;
    font-size: 14px;
    line-height: 130%;
    font-weight: 300;
    font-family: 'Manrope';
}
.price_buttons_panel{
    display: flex;
    align-items: center;
    gap: 10px;
}

.button_add{
    border: 3px #FFF solid;
    background-color: transparent;
    color: #FFF;
    font-family: 'Manrope';
    font-size: 16px;
    width: 120px;
    height: 38px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    user-select: none;
    cursor: pointer;
}

.ingredients_panel{
    visibility: hidden;
    display: flex;
    flex-direction: column;
    z-index: 10;
    position: absolute; 
    padding: 10px;
}
.ingredients_head{
    display: flex;
    flex-direction: column;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}
.ingredients_head h1{
    margin: 0px;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 24px;
    color: #FFF;
}
.button_back{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    background-color: transparent;
    border: 2px #FFF solid;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    margin: 0px;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 18px;
    color: #FFF;
}

.ingredients_head button img{
    margin-top: 2px;
    width: 100%;
}
.ingredients_container{
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    margin-top: 15px;
    height: 20px;
    width: 100%;
    overflow-y: auto;
    padding: 10px 10px;
    box-sizing: border-box;
}
.ingredients_container::-webkit-scrollbar{
    overflow: overlay;
    display: auto;
    width: 3px;
}
.ingredients_container::-webkit-scrollbar-thumb{
    background-color: var(--app-sb-thmb-color);
    transition: 0.3s;
}
  
.ingredients_container::-webkit-scrollbar {
    background: var(--app-sb-back-color);
    transition: 0.3s;
}
.ingredients_list{
    margin-top: 10px;
    
}
.ingredients{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ingredients_group_root{
    /* box-shadow: 0px 0px 0px 2px #000000; */
    padding: 5px 10px;
    background-color: #00000052;
    border-radius: 15px;
}
.ingredients_group_root h1{
    margin: 8px;
    padding-bottom: 10px;
    font-size: 16px;
    color: #FFF;
    font-family: 'Manrope';
    font-weight: 200;
    position: relative;
}
.ingredients_group_root h1::after{
    content: "";
    height: 1px;
    position: absolute;
    left: -2px;
    bottom: 3px;
    right: 0px;
    background-color: #ffffff86;

}
.ingredient_root {
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    min-height: 40px;
    padding: 5px 15px;
    margin: 5px 0px;
    box-sizing: border-box;
}

.ingredient_root h2{
    margin: 0px;
    flex: 1;
    font-size: 18px;
    font-weight: 300;
    color: #FFF;
    font-family: 'Manrope';
}
.ingredient_root h3{
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    color: #FFF;
    font-family: 'Manrope';
}
.ingredient_root h3 span{
    user-select: none;
    margin-left: 4px;
    opacity: 0.7;
    font-size: 0.8em;
}

.ingredient_root_selected{
    border-radius: 10px;
    /* box-shadow: 0px 0px 0px 2px #FFF; */
    background: #a72839;
}
.ingredient_root_selected h2{
    font-weight: 800;
}

.ingredient_quantity{
    display: flex;
    flex-direction: row;
}

.ingredient_quantity button{
    user-select: none;
    border: none ;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
    outline: none;
    background-color: transparent;
    width: 30px;
    height: 30px;
}

.ingredient_quantity p{
    user-select: none;
    color: #ffffff;
    font-size: 20px;
    padding: 0px 10px;
    margin: 0px;
    font-weight: 600;
    font-family: 'Manrope';
}

.cart_buttons{
    width: 120px;
    height: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    background-color: transparent;
    border:  3px #FFF solid;
    box-sizing: border-box;
    background-color: transparent;
    transition: .3s;
    user-select: none;
}
.cart_buttons p{
    font-size: 26px;
    box-sizing:border-box;
    margin: 0;
    color: #FFF;
    flex: 1;
    text-align: center;
    font-weight: 700;
}
.cart_buttons button{
    height: 100%;
    margin: 0px;
    font-size: 26px;
    color: #FFF;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 500;
    flex: 1;
}



@media (max-width: 740px){
    :root{
        --product-border-radius: 0px;
    }
    .content{
        width: 100%;
        height: 100%;
        left: 0px;
        right: 0px;
    }
    .content_normal{
        margin: auto;
        left: 0px !important;
        top: 0px !important;
        width: 100% !important;
        height: 100dvh !important;
    }
    .main_gradient{
        background: var(--d-product-gradient-h);
        left: 0;
        right: 0px;
        top: 50%;
        bottom: 0px;
    }
    .info_panel{
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 10px;
        margin-bottom: 20px;
    }
    .ingredients_panel{
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 10px;
        margin-bottom: 0px;
        max-height: 350px;
    }
    @keyframes right_to_left_hide {
        from{
            opacity: 1;
            transform: translateY(0px);
        }
        to{
            opacity: 0;
            transform: translateY(-100%);
        }
    }
    
    
    @keyframes right_to_left_show {
        from{
            opacity: 0;
            transform: translateY(100%);
        }
        to{
            opacity: 1;
            transform: translateY(0px);
        }
    }
}

@media (min-width: 741px){
    :root{
        --product-border-radius: 25px;
    }
    .content{
        width: 700px;
        height: 500px;
        left: 0px; 
        right: 0px;
        top: 0px; 
        bottom: 0px;
    }
    .content_normal{
        margin: auto;
        left: 0px !important;
        top: 0px !important;
    }
    .main_gradient{
        background: var(--d-product-gradient-v);
        left: 20%;
        right: 0px;
        top: 0px;
        bottom: 0px;
    }
    .info_panel{
        left: 40%;
        right: 0px;
        top: 30%;
        bottom: 0px;
        padding: 10px;
    }
    .ingredients_panel{
        left: 40%;
        right: 0px;
        top: 30%;
        
        bottom: 0px;
        padding: 0px;
    }
    @keyframes right_to_left_hide {
        from{
            opacity: 1;
            transform: translateX(0px);
        }
        to{
            opacity: 0;
            transform: translateX(-100%);
        }
    }
    
    
    @keyframes right_to_left_show {
        from{
            opacity: 0;
            transform: translateX(100%);
        }
        to{
            opacity: 1;
            transform: translateX(0px);
        }
    }
}


@media (hover:hover){
    .close:hover{
        transform: scale(1.2);
    }
    
}

@keyframes bg_show {
    from{
        backdrop-filter: blur(0px);
        background-color: transparent;
        
    }
    to{
        backdrop-filter: blur(12px);
        background-color: var(--navbar-bg-under);
    }
}

@keyframes show_ui_controls {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes hide_ui_controls {
    from{
        opacity: 1;
        
    }
    to{
        opacity: 0;
    }
}

