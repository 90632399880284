.root{
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    animation: content_show 0.7s  ease-out;
    h2{
        font-size: 22px;
        color: var(--text-color-strong);
    }
    h3{
        text-align: center;
        width: 100%;
        font-size: 20px;
        font-family: 'Manrope';
        font-weight: 800;
        color: var(--text-color);
        margin-top: 15px;
    }
}


.content_root{
    box-shadow: 2px 2px 12px 2px #00000042;
    display: flex;
    margin:  0px auto;
    width: 400px;
    height: 400px;
    align-items: center;
    // background-color: #00000023;
    border: solid 2px var(--d-product-border);
    border-radius: 15px;
    animation: content_show 0.3s  ease-out;
}

.root{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}


.contnet_phone, .content_code{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
}


.top{
    display: flex;
    height: 60px;
    width: 100%;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    justify-content: flex-start;
}

.center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    h1{
        margin: 0;
        padding: 12px 15px;
        font-family: 'Manrope';
        text-align: center;
        font-size: 17px;
        color: var(--text-color-strong);
        font-weight: 800;
    }
    
    h2{
        margin: 0px;
        padding: 2px 15px;
        font-family: 'Manrope';
        text-align: center;
        font-size: 14px;
        color: var(--text-color);
        font-weight: 600;
    }
    h3{
        margin: 0px 0px;
        padding: 4px 15px;
        line-height: normal;
        font-family: 'Manrope';
        align-self: flex-start;
        text-align: left;
        font-size: 14px;
        color: var(--text-color);
        font-weight: 900;
        width: auto;
    }
}
.bottom{
    margin: 0px;
    display: flex;
    height: 100px;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}




/* PHONE */
.phone_enter_title{
    font-family: 'Manrope';
    margin-bottom: -10px;
    font-size: 15px;
    color: var(--text-color);
    opacity: 0.7;
    font-weight: 400;
}
.phone_container{
    margin-top: 15px;
    gap: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.phone_prefix{
    color: var(--text-color);
    text-align: left;
    font-size: 22px;
    font-weight: 700;
}
.phone{
    user-select: none;
    border: 2px solid var(--d-product-border);
    border-radius: 7px;
    background-color: transparent;
    color: var(--text-color-strong);
    text-align: left;
    font-size: 22px;
    font-weight: 700;
    width: 160px;
    height: 40px;
    /* letter-spacing: 2px; */
    padding-left: 10px;
    transition: 0.3s;
    &:focus{
        border: 2px solid #666;
        outline: #666 1px solid;
    }
    &::placeholder{
        font-size: 20px;
        letter-spacing: 0px;
        color: var(--text-color);
        opacity: 0.2;
        user-select: none;
    }
}

.input_phone {
    display: flex;
    flex-direction: row;
    width: 160px;
    position: relative;
    
    input{
        width: 100%;
        box-sizing: border-box;
        margin-top: 3px;
        flex: 1;
        padding-left: 24px;
        padding-top: 5px;
        padding-bottom: 5px;
        background: transparent;
        border: none;
        font-size: 22px;
        color: var(--text-color);
        outline: none;
        display: flex;
        font-family: 'Manrope';
        align-items: center;
        font-weight: 400;
        transition: 0.3s;
        border-bottom: 2px var(--text-color-placeholder) solid;
        &:focus + label, 
        &:not(:placeholder-shown) + label{
            color: var(--app-accent-color);
            font-size: 12px;
            top: -8px;
            left: 3px;
            height: 15px;
            font-weight: 700;
        }
        &::placeholder{
            opacity: 0;
        }
        &:focus{
            border-bottom: 2px var(--app-accent-color) solid;
        }
        &:valid ~ p, 
        &:focus ~ p{
            opacity: 1;
            visibility: visible;
        }
    }
    
    label{
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;    
        display: flex;
        align-items: center;
        color: var(--text-color-placeholder);
        font-size: 22px;
        font-family: 'Manrope';
        font-weight: 400;
        cursor: text;
        user-select: none;
        transition: 0.2s ease-out;
        min-width: 0;
        max-width: calc(100% - 20px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    p{
        padding-top: 1px;
        content: "+7";
        font-size: 22px;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        color: var(--text-color);
        display: flex;
        align-items: center;
        opacity: 0;
        font-family: 'Manrope';
        font-weight: 400;
        transition: 0.3s;
        visibility: collapse;
        pointer-events: none;
    }
    
}

.input_phone2 {
    display: flex;
    flex-direction: row;
    position: relative;
    
    input{
        width: 100%;
        box-sizing: border-box;
        margin-top: 3px;
        flex: 1;
        padding-left: 18px;
        padding-top: 5px;
        padding-bottom: 5px;
        background: transparent;
        border: none;
        font-size: 16px;
        color: var(--text-color);
        outline: none;
        display: flex;
        font-family: 'Manrope';
        align-items: center;
        font-weight: 700;
        transition: 0.3s;
        border-bottom: 1px var(--text-color-placeholder) solid;
        &:focus + label, 
        &:not(:placeholder-shown) + label{
            color: var(--app-accent-color);
            font-size: 10px;
            top: -8px;
            left: 3px;
            height: 15px;
            font-weight: 800;
        }
        &::placeholder{
            opacity: 0;
        }
        &:focus{
            border-bottom: 2px var(--app-accent-color) solid;
        }
        &:valid ~ p, 
        &:focus ~ p{
            opacity: 1;
            visibility: visible;
        }
    }
    
    label{
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;    
        display: flex;
        align-items: center;
        color: var(--text-color-placeholder);
        font-size: 16px;
        font-family: 'Manrope';
        font-weight: 600;
        cursor: text;
        user-select: none;
        transition: 0.2s ease-out;
        min-width: 0;
        max-width: calc(100% - 20px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    p{
        padding-top: 1px;
        content: "+7";
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        color: var(--text-color);
        display: flex;
        align-items: center;
        opacity: 0;
        font-family: 'Manrope';
        font-weight: 700;
        transition: 0.3s;
        visibility: collapse;
        pointer-events: none;
    }
    
}


.input_simple {
    position: relative;
    input{
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus {
            background-color: transparent;
            -webkit-text-fill-color: #ffffff;
            -webkit-box-shadow: 0 0 0px 40rem rgb(29, 29, 29) inset;
        }
        
        margin-top: 3px;
        padding: 5px 3px;
        width: 100%;
        background: transparent;
        border: none;
        font-size: 16px;
        box-sizing: border-box;
        color: var(--text-color);
        outline: none;
        display: flex;
        font-family: 'Manrope';
        align-items: center;
        font-weight: 700;
        transition: 0.3s;
        border-bottom: 1px var(--text-color-placeholder) solid;
    }
    label{
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;    
        display: flex;
        align-items: center;
        color: var(--text-color-placeholder);
        font-size: 16px;
        font-family: 'Manrope';
        font-weight: 600;
        cursor: text;
        user-select: none;
        transition: 0.2s ease-out;
        min-width: 0;
        max-width: calc(100% - 20px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    input:focus + label,
    input:not(:placeholder-shown) + label{
        color: var(--app-accent-color);
        font-size: 10px;
        top: -8px;
        left: 3px;
        height: 15px;
        font-weight: 800;
    }
    input::placeholder{
        color: transparent;
        user-select: none;
    }
    input:focus{
        border-bottom: 2px var(--app-accent-color) solid;
    }
}




.button_accept{
    background-color: var(--app-accent-color);
    font-size: 15px;
    color: #FFF;
    font-family: 'Manrope';
    font-weight: 700;
    border-radius: 35px;
    outline: none;
    border: none;
    padding: 10px 20px;
    transition: .3s;
    outline: 2px transparent solid !important;
    outline-offset: 2px !important;
    &:hover:enabled{
        outline: 2px #79797967 solid !important;
        outline-offset: 2px !important;
    }
    &:focus{
        outline: 2px #79797967 solid !important;
        outline-offset: 2px !important;
    }
    &:disabled{
        color: #ffffff;
        background-color: rgba(56, 56, 56, 0.493);
    }
}


/* VERIFICATION CODE */
.verification_code_inputs{
    display: flex;
    margin-top: 15px;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    position: relative;
}
.code_number{
    user-select: none;
    border: 2px solid #ffffff11;
    border-radius: 7px;
    background-color: transparent;
    color: #FFF;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    width: 50px;
    height: 50px;
    caret-color: transparent;
    transition: .5s;
    &:focus{
        /* border: 0px solid #555; */
        outline: none;
        border: 3px solid #812222; 
    }
    &::placeholder{
        border: 0px solid #555;
        color: #ffffff0c;
        user-select: none;
        font-weight: 200;
    }
}

.verification_code_text p {
    margin: 3px;
    text-align: center;
    padding: 0px 20px;
    font-family: 'Manrope';
    font-size: 12px;
    color: #ffffffab;
    font-weight: 700;
}

.back_button{
    width: 40px;
    height: 40px;
    top: -30px;
    left: 0px;
    background-color: #ffffff00;
    border: none;
    display: flex;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    transition: .5s;
    outline: none;
    border: none;
    &:hover{
        background-color: #ffffff09;
        outline: none;
        border: none;
    }
    &:focus{
        background-color: #ffffff02;
        outline: 3px #ffffff18 solid;
        outline-offset: 2px;
        border: none;
    }
    img{
        margin-top: 2px;
        opacity: 0.4;
        width: 20px;
        height: 17px;
    }
}


.verification_root{
    position: fixed;
    background-color: #000000c4;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
}

.msg{
    height: 25px;
    font-size: 12px;
    color: #c76161;
    font-family: 'Manrope';
    font-weight: 500;
    margin-bottom: 5px;
    text-align: center;
    max-width: 300px;
}


.cards_list{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 40px 0px;
}



.name{
    user-select: none;
    border: 2px solid var(--d-product-border);
    border-radius: 7px;
    background-color: transparent;
    color: var(--text-color-strong);
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    width: auto;
    height: 40px;
    /* letter-spacing: 2px; */
    padding-left: 10px;
    &:focus{
        border: 2px solid #666;
        outline: #666 1px solid;
    }
    &::placeholder{
        font-size: 17px;
        
        letter-spacing: 0px;
        color: #444;
        user-select: none;
    }
}


.reg_form{
    display: flex;
    align-items: left;
    flex-direction: column;
    gap: 25px;
    width: 300px;
}

.reg_form_label{
    margin-top: 5px;
    margin-bottom: -4px;
    margin-left: 15px;
    color: rgb(212, 111, 111);
    font-size: 14px;
    font-weight: 600;
}


.modal_root{
    z-index: 3000;
    background-color: #000000c5;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
.modal_content{
    z-index: 3001;
    box-shadow: 3px 3px 11px 3px rgba(0, 0, 0, 0.473);
    display: flex;
    margin:  0px auto;
    width: 400px;
    height: 400px;
    align-items: center;
    background-color: #1a1a1a;
    border-radius: 15px;
}

.top_message{
    z-index: 600;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #812222;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 50px;
    animation: message_show 0.3s ease-out;
     p{
        max-width: 350px;
        font-family: 'Manrope';
        margin: 0px;
        font-size: 16px;
        font-weight: 600;
        color: #FFF;
    }
}

.registered_client_phone{
    border: 2px solid var(--app-accent-color);
    padding: 0px 6px;
    font-family: 'Manrope';
    color: var(--text-color-strong);
    font-weight: 800;
    border-radius: 100px;
    transition: .3s;
    img{
        padding: 0px;
        user-select: none;
        cursor: pointer;
        opacity: 0.7;
        padding: 8px;
        padding-right: 4px;
        width: 10px;
        height: 10px;
        transition: .3s;
        &:hover{
            opacity: 1;
        }
    }
}

@keyframes message_show {
    from{
        transform: translateY(-100%);
        opacity: 0.5;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}


@keyframes content_show {
    from{
        transform: scale(0.9);        
        opacity: 0.5;
    }
    to{
        transform: scale(1);        
        opacity: 1;
    }
}

@keyframes content_hide {
    from{
        transform: scale(1);        
        opacity: 1;
    }
    to{
        transform: scale(0.9);        
        opacity: 0.5;
    }
}