.root{
    position: fixed;
    background-color: #111;
    height: auto;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 15px 20px;
    z-index: 10000;
    box-shadow: 0px -5px 32px 15px #ffffff6b;
}
.content{
    position: relative;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.root h1{
    font-family: 'Manrope';
    font-size: 15px;
    padding-right: 40px;
    color: #c7c7c7;
    /* text-decoration: underline; */
    font-weight: 900;
}
.root h2{
    font-family: 'Manrope';
    line-height: normal;
    font-size: 12px;
    color: #ffffff9d;
    font-weight: 500;
}
h3{
    color: #FFF;
}
.close{
    border-radius: 100%;
    border: 1px #ffffff10 solid;
    position: absolute;
    background-color: transparent;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -10px;
    right: -10px;
    height: 35px;
    width: 35px;
}
.close img{
    width: 11px;
    height: 11px;
}
.close:hover{
    background-color: #ffffff15;
}