.root{
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    background-color: var(--d-product-bg-color);
    /* box-shadow: 0px 0px 0px 1px var(--d-product-shadow-color); */
    box-sizing: border-box;
    transition: .3s;
}

.image_root{
    box-sizing:content-box;
    transition: 0.3s;
    overflow: hidden;
    background-color: var(--d-product-img-bg);
}
.image_root img{
    transition: .3s;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cart_button_add{
    outline: none;
    text-transform: uppercase;
    font-family: 'Manrope';
    text-align: center;
    background-color: transparent;
    color: var(--text-color-strong);
    opacity: .4;
    border: var(--text-color-strong) solid 2px;
    font-weight: 700;
    transition: .3s;
    user-select: none;
}

.cart_button_add_in_cart{
    opacity: 1;
    border-color: var(--app-accent-color);
    background-color: var(--app-accent-color);
    color: #FFF;
}



.cart_button_add:active{
    opacity: 1;
    border-color: var(--app-accent-color);
    background-color: var(--app-accent-color);
    color: #FFF;
}

.cart_buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    background-color: transparent;
    border-color: var(--app-accent-color);
    background-color: var(--app-accent-color);
    transition: .3s;
    user-select: none;
}
.cart_buttons p{
    height: 100%;
    padding-top: 3px;
    box-sizing:border-box;
    margin: 0;
    color: #FFF;
    flex: 1;
    text-align: center;
    font-weight: 700;
}
.cart_buttons button{
    height: 100%;
    margin: 0px;
    
    color: #FFF;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 500;
    flex: 1;
}


.properties{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.weight{
    color: var(--text-color-strong);
    opacity: 0.5;
    font-size: 14px;
    font-weight: 300;
    margin-right: 15px;
    padding-top: 3px;
}
.price{
    transition: 0.3s;
    margin-right: 5px;
    text-align: end;
    font-size: 22px;
    font-weight: 700;
    color: var(--text-color-strong);
}


@media (max-width: 399px){
    .root{
        width: 100%;
        flex-direction: row;
        min-height: 50px;
        padding: 5px;
        height: auto;
    }
    .image_root{
        height: 80px;
        width: 80px;
    }
    .info{
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    .name{
        transition: 0.3s;
        padding: 0px 7px;
        font-family: 'Manrope';
        font-size: 13px;
        text-align: center;
        font-weight: 600;
        line-height: 110%;
        flex: 1;
        text-align: left;
        color: var(--d-product-name-color);
    }
    .bottom{
        padding: 0px 0px;
        display: flex;
        flex-direction: row;
        justify-content:flex-end;
        align-items: flex-end;
    }
    .cart_button_add{
        height: 28px;
        width: 80px;
        font-size: 11px;
    }
    .cart_buttons{
        height: 28px;
        width: 80px;
    }
    .cart_buttons p{
        font-size: 16px;
    }
    .cart_buttons button{
        font-size: 18px;
    }
    .properties{
        margin-bottom: 5px;
    }
    .price{
        font-size: 15px;
    }
}

@media (min-width: 400px) and (max-width: 740px){
    .root{
        width: 49%;
        flex-direction: column;
        height: auto;
        padding: 6px;
    }
    .image_root{
        height: 180px;
        width: 100%;
    }
    .info{
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    .name{
        transition: 0.3s;
        padding: 10px 5px;
        font-family: 'Manrope';
        font-size: 14px;
        line-height: 110%;
        text-align: center;
        font-weight: 400;
        flex: 1;
        color: var(--d-product-name-color);
    }
    .bottom{
        padding: 0px 0px;
        display: flex;
        flex-direction: row;
        justify-content:flex-end;
        align-items: flex-end;
    }
    .cart_button_add{
        height: 30px;
        width: 90px;
        margin-top: 10px;
        font-size: 13px;
    }
    .cart_buttons{
        height: 30px;
        width: 90px;
        margin-top: 10px;
    }
    .cart_buttons p{
        font-size: 20px;
    }
    .cart_buttons button{
        font-size: 20px;
    }
}

@media (min-width: 741px){
    .root{
        width: 250px;
        flex-direction: column;
        height: auto;
        padding: 10px;
    }
    .image_root{
        height: 230px;
        width: 100%;
    }
    .info{
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    .name{
        transition: 0.3s;
        padding: 10px 0px;
        font-family: 'Manrope';
        font-size: 16px;
        line-height: 110%;
        text-align: center;
        font-weight: 600;
        flex: 1;
        color: var(--d-product-name-color);
    }
    .bottom{
        padding: 0px 0px;
        display: flex;
        flex-direction: row;
        justify-content:flex-end;
        align-items: flex-end;
    }
    .cart_button_add{
        height: 35px;
        width: 100px;
        font-size: 14px;
        margin-top: 10px;
    }
    .cart_buttons{
        height: 35px;
        width: 100px;
        margin-top: 10px;
    }
    .cart_buttons p{
        font-size: 20px;
    }
    .cart_buttons button{
        font-size: 24px;
    }
}


@media (hover :hover){
    .root:hover{
        background-color: var(--d-product-bg-color-hover);
        /* box-shadow: 0px 0px 9px 4px var(--d-product-shadow-color); */
    }
    
    .root:hover .image_root img{
        transform: scale(1.1);
    }
    .cart_button_add:hover{
        opacity: 1;
        border-color: var(--app-accent-color);
        background-color: var(--app-accent-color);
        color: #FFF;
    }
    .cart_buttons button:hover{
        opacity: 0.6;
    }
}