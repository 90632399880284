.root{
    position: fixed;
    background-color: #00000000;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 125;
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* animation: show_root .3s ease-out; */
    animation-fill-mode: both;
}

.content{
    width: 500px;
    height: auto;
    border-radius: 25px;
    background-color: var(--body-background);
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    animation: show_content 0.3s ease-out;
}

.content form{
    align-content: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 18px;
}
.input_city, .input_street, .input_house, .input_flat, 
.input_entrance, .input_floor, .input_intercom{
    position: relative;
    box-sizing: border-box;
    min-width: 0;
}


.address_input_root{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;    
    margin-bottom: 35px;
    margin-top: 35px;
}

.input_city, .input_street {
    width: calc(100% );
}

.input_house, .input_flat{
    width: calc(50% - 9px);
}
.input_entrance, .input_floor, .input_intercom{
    width: calc(33.33% - 12px);
}

.content h1{
    font-size: 22px;
    color: var(--text-color-strong);
    margin: 0px;
    font-family: 'Manrope';
    font-weight: 800;
}

/* .content label{
    position: absolute;
    top: -8px;
    padding: 0px 6px;
    background-color: #222;
    left: 10px;
    color: #FFF;
    font-size: 12px;
    font-family: 'Manrope';
    font-weight: 600;
} */

.content label{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;    
    display: flex;
    align-items: center;
    color: var(--text-color-placeholder);
    font-size: 16px;
    font-family: 'Manrope';
    font-weight: 600;
    cursor: text;
    user-select: none;

    
    transition: 0.2s ease-out;

    min-width: 0;
    max-width: calc(100% - 20px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.content input:focus + label,
.content input:valid + label{
    color: var(--app-accent-color);
    font-size: 10px;
    top: -8px;
    left: 3px;
    height: 15px;
    font-weight: 800;
    
}

.content input:focus{
    border-bottom: 2px var(--app-accent-color) solid;
}

.input_address{
    padding: 5px 8px;
    width: 100%;
    border: 2px #5a5a5a solid;
    background: transparent;
    font-size: 17px;
    box-sizing: border-box;
    color: var(--text-color);
    border-radius: 8px;
    outline: none;
    display: flex;
    font-family: 'Manrope';
    align-items: center;
    font-weight: 700;
    
}

.address_form input{
    margin-top: 3px;
    padding: 5px 3px;
    width: 100%;
    background: transparent;
    border: none;
    font-size: 16px;
    box-sizing: border-box;
    color: var(--text-color);
    outline: none;
    display: flex;
    font-family: 'Manrope';
    align-items: center;
    font-weight: 700;
    transition: 0.3s;
    border-bottom: 2px var(--text-color-placeholder) solid;
}


/* .content input:focus{
    outline: #eb2e3e44 2px solid !important;
    outline-offset: 2px;
}
.content input::placeholder{
    color: #555;
} */



.result_dropdown_root{
    z-index: 3;
    user-select: none;
    padding: 15px 15px;
    background-color: #e0e0e0;
    position: absolute;
    top: calc(100% + 5px);
    min-height: 100%;
    max-height: 150px;
    left: 0px;
    right: 0px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 25px;
    border-radius: 0px 0px 20px 20px;
    animation: show_dropdown 0.3s ease-out;    
}
.result_dropdown_address{
    cursor: pointer;
    font-size: 15px;
    color: #292929;
    font-family: 'Manrope';
    font-weight: 600;
}

.button_accept{
    background-color: var(--app-accent-color);
    font-size: 14px;
    color: #FFF;
    font-family: 'Manrope';
    font-weight: 600;
    border-radius: 35px;
    outline: none;
    border: none;
    align-self: center;
    padding: 8px 15px;
    transition: .3s;
    outline: 2px transparent solid !important;
    outline-offset: 2px !important;
}
.button_accept:hover:enabled{
    outline: 2px #ffffff33 solid !important;
    outline-offset: 2px !important;
}
.button_accept:focus{
    outline: 2px #ffffff33 solid !important;
    outline-offset: 2px !important;
}

.button_close{
    background-color: transparent;
    font-size: 14px;
    user-select: none;
    color: var(--text-color);
    font-family: 'Manrope';
    font-weight: 600;
    border-radius: 35px;
    outline: none;
    border: none;
    align-self: center;
    padding: 8px 15px;
    transition: .3s;
    outline: 2px transparent solid !important;
    outline-offset: 2px !important;
    border: 2px #80808077 solid ;
}
.button_close:hover:enabled{
    outline: 2px #80808077 solid !important;
    outline-offset: 2px !important;
}
.button_close:focus{
    outline: 2px #80808077 solid !important;
    outline-offset: 2px !important;
}

.bottom_panel{
    margin-top: 15px;
    align-items: flex-end;
    justify-items: flex-end;
    display: flex;
    gap: 10px;
    flex-direction: row;
}


@keyframes show_dropdown {
    from{
        opacity: 0;
    }   
    to{
        opacity: 1;
    } 
}

@keyframes show_root {
    from{
        backdrop-filter: blur(0px);
        background-color: transparent;
    } 
    to{
        backdrop-filter: blur(5px);
        background-color: #00000033;
    } 
}

@keyframes hide_root3 {
    from{
        backdrop-filter: blur(5px);
        background-color: #00000025;
    } 
    to{
        backdrop-filter: blur(0px);
        background-color: transparent;
    } 
}

@keyframes show_content {
    from{
        transform: scale(0.8);
        opacity: 0;
    } 
    to{
        transform: scale(1);
        opacity: 1;
    } 
}

@keyframes hide_content2 {
    from{
        transform: scale(1);
        opacity: 1;
    } 
    to{
        transform: scale(0.8);
        opacity: 0;
    } 
}