.root{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    background: var(--body-background);
    border-radius: 20px;
}


.contnet_phone, .content_code{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.center h1{
    margin: 0;
    padding: 12px 15px;
    font-family: 'Manrope';
    text-align: center;
    font-size: 17px;
    color: #ffffffd0;
    font-weight: 700;
}

.center h2{
    margin: 0px;
    padding: 2px 15px;
    font-family: 'Manrope';
    text-align: center;
    font-size: 12px;
    color: #ffffff8c;
    font-weight: 500;
}
.center h3{
    margin: 0px 0px;
    padding: 4px 15px;
    line-height: normal;
    font-family: 'Manrope';
    text-align: left;
    font-size: 12px;
    color: #ffffff8c;
    font-weight: 500;
    width: 100%;
}

.top{
    display: flex;
    height: 60px;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    box-sizing: border-box;
}
.center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    
}
.bottom{
    display: flex;
    height: 70px;
    width: 100%;
    align-items: center;
    justify-content: center;
    
}

/* PHONE */
.phone_enter_title{
    font-family: 'Manrope';
    font-size: 16px;
    color: #ffffffab;
    font-weight: 700;
}
.phone_container{
    margin-top: 15px;
    gap: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.phone_prefix{
    color: #FFF;
    text-align: left;
    font-size: 22px;
    font-weight: 700;
}
.phone{
    user-select: none;
    border: 2px solid #fcfcfc10;
    border-radius: 7px;
    background-color: transparent;
    color: #FFF;
    text-align: left;
    font-size: 22px;
    font-weight: 700;
    width: 160px;
    height: 40px;
    /* letter-spacing: 2px; */
    padding-left: 10px;
}
.phone:focus{
    border: 2px solid #666;
    outline: #666 1px solid;
}
.phone::placeholder{
    font-size: 20px;
    
    letter-spacing: 0px;
    color: #444;
    user-select: none;
}
.button_accept{
    background-color: var(--app-accent-color);
    font-size: 15px;
    color: #FFF;
    font-family: 'Manrope';
    font-weight: 700;
    border-radius: 35px;
    outline: none;
    border: none;
    padding: 10px 20px;
    transition: .3s;
    outline: 2px transparent solid;
    outline-offset: 2px;
}
.button_accept:hover:enabled{
    outline: 2px #ffffff46 solid;
    outline-offset: 2px;
}
.button_accept:focus{
    outline: 2px #ffffff46 solid;
    outline-offset: 2px;
}
.button_accept:disabled{
    color: #ffffff75;
    background-color: rgba(56, 56, 56, 0.493);
}


/* VERIFICATION CODE */
.verification_code_inputs{
    display: flex;
    margin-top: 15px;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    position: relative;
}
.code_number{
    user-select: none;
    border: 2px solid var(--navbar-border-color);
    border-radius: 7px;
    background-color: transparent;
    color: var(--text-color);
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    width: 50px;
    height: 50px;
    caret-color: transparent;
    transition: .5s;
    box-sizing: border-box;
}
.code_number:focus{
    /* border: 0px solid #555; */
    outline: none;
    border: 3px solid var(--app-accent-color);
}
.code_number::placeholder{
    color: var(--text-color);
    opacity: 0.1;
    user-select: none;
    font-weight: 200;
}
.verification_code_text p {
    margin: 3px;
    text-align: center;
    padding: 0px 20px;
    font-family: 'Manrope';
    font-size: 12px;
    color: var(--text-color);
    font-weight: 700;
}

.back_button{
    width: 40px;
    height: 40px;
    top: -30px;
    left: 0px;
    background-color: #ffffff00;
    border: none;
    display: flex;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    transition: .5s;
    outline: none;
    border: none;
    
}
.back_button:hover{
    background-color: #ffffff09;
    outline: none;
    border: none;
}
.back_button:focus{
    background-color: #ffffff02;
    outline: 3px #ffffff18 solid;
    outline-offset: 2px;
    border: none;
}
.back_button img{
    margin-top: 2px;
    opacity: 0.4;
    width: 20px;
    height: 17px;
}

.msg{
    margin-top: 15px;
    height: 25px;
    font-size: 14px;
    color: #c76161;
    font-family: 'Manrope';
    font-weight: 500;
    margin-bottom: 5px;
}

