.checkbox_container{
    color: #fff;
    display: flex;
    justify-content: flex-end;
    margin: 0px;
    padding: 5px 10px;
}

.checkbox_container label{
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    position: relative;
    font-family: 'Manrope';
    font-weight: 300;
    color: var(--text-color);
    font-size: 14px;
    width: auto;
    flex: auto;
}
.checkbox_container input{
    
    opacity: 0;
    position: absolute;
}
.checkbox_container label::before{
    content: '';
    width: 20px;
    min-width: 20px;
    margin-top: 5px;
    height: 20px;
    min-height: 20px;
    margin-right: 1em;
    border-radius: .25em;
    border: .15em solid #912727;
}
.checkbox_container label:hover::before,
.checkbox_container input:hover + label::before{
    background-color: #ffffff10;
}

.checkbox_container input:focus + label::before{
    
}

.checkbox_container input:checked + label::before{
    content: '';
    
    background-color: #912727;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    box-shadow: 0px 0px 0px 3px #9127276e;
}

.checkbox_container input:checked + label::after{
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    bottom: 0px;
    left: 7px;
    width: 7px;
    height: 12px;
    border: solid var(--body-background);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    transition: 0.3s;
}
