

.App {
  /* min-height: -webkit-fill-available ; */
  min-height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navlogo{
  height: 55px;
  padding: 6px;
}

.navlink-text{
  font-family: Manrope;
  font-weight: 600;
}

ul{
  margin: 0px;
}


footer {
  background: var(--app-footer-color);
  min-height: 100px;
  padding: 10px 20px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  clear: both;
  margin-top: auto;
}

.social-icon,
.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.social-icon__item,
.menu__item {
  list-style: none;
}

.social-icon__link {
  font-size: 1rem;
  color: var(--navbar-text-color-menu-item);
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}
.social-icon__link:hover {
  transform: translateY(-10px);
}

.menu__link {
  font-size: 1rem;
  color: var(--navbar-text-color-menu-item);
  font-family: Manrope;
  margin: 0 15px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 500;
}

.menu__link:hover {
  opacity: 1;
  transform: translateY(-5px);
}

.footer p {
  color: #fff;
  margin: 15px 0 10px 0;
  font-size: 1rem;
  font-weight: 300;
}

.copyright {
  margin-top: 0px;
}

.copyright p{
  transition: 0.5s;
  font-family: Manrope;
  font-weight: 700;
  color:var(--navbar-text-color-menu-item);
  opacity: 0.7;
  font-size: 12px;
  text-align: center;
  margin-bottom: 30px;
}

.wowlogo{
  height: 30px;
  margin: 10px;
}
.cosialmediaicon{
  height: 22px;
  margin: 10px;
}
.footer ul{
  margin-left: 0px;
  padding-left: 0px;
}

.themeSwitcher{
  z-index: 10000;
  transition: .5s ease-in-out;
  position: fixed;
  bottom: 15px;
  border-radius: 100%;
  right: 15px;
  border: var(--theme-sw-border) 2px solid;
  background-color: var(--theme-sw-bg);
  width: 40px;
  height: 40px;
}