.product_root{
    max-width: 550px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-items: start;
    border-radius: 10px;
    /* background-color: var(--d-product-bg-color); */
    min-height: 85px;
    width: 100%;
    gap: 15px;
    transition: 0.3s;
    padding: 10px;
    margin-bottom: 8px;
    box-sizing: border-box;
}

.product_root img{
    object-fit: cover;
    height: 65px;
    width: 65px;
    min-width: 60px;
    border-radius: 8px;
    outline: 2px #80808077 solid !important;
    outline-offset: 3px;
}
.cart_product_info{
    display: flex;
    flex-direction: row;
    flex: 1;
}


.cart_product_name{
    display: flex;
    flex-direction: column;
    align-content: center;
    min-width: 120px;
    flex: 1;
}
.cart_product_name p{
    margin-top: 0px;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 100%;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
    opacity: 0.7;
    font-family: 'Manrope';
}

.cart_product_quantity_and_price{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    width: 160px;
}

.cart_product_price{
    color: var(--text-color);
    font-family: 'Manrope';
    font-size: 15px;
    font-weight: 500;
    text-align: right;
    flex: 1;
    opacity: 0.8;
}
.cart_product_sum{
    color: var(--text-color);
    font-family: 'Manrope';
    font-size: 15px;
    font-weight: 800;
    text-align: center;
    flex:1;
}

.cart_product_buttons{
    display: flex;
    flex-direction: row;
    outline: none;
    border: 1px solid var(--d-product-border);
    border-radius: 7px;
    /* background-color: transparent; */
    transition: .3s;
    user-select: none;
    height: 30px;
    width: 80px;
}
.cart_product_buttons p{
    color: var(--text-color);
    height: 100%;
    box-sizing:border-box;
    font-size: 20px;
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
}
.cart_product_buttons button{
    color: var(--text-color);
    height: 100%;
    background-color: transparent;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    border: none;
    outline: none;
    font-weight: 500;
    flex: 1;
}


@media (max-width: 640px){
    .cart_product_info{
        flex-direction: column;
        
    }
    .cart_product_name{
        flex: 1;
    }
    .cart_product_quantity_and_price{
        align-self: flex-end;
        align-content: flex-end;
    }

}



