.root{
    
    display: flex;
    justify-content: center;
    z-index: 3;
    /* background-color: #000000c4; */
}
.background{
    z-index: 101;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    opacity: 0.7;
    background-color: var(--navbar-bg-under);
    animation: show_bg .3s ease-out;
}

.themeSwitcher{
    width: 40px;
    height: 40px;
    min-width: 40px;
    margin-right: 8px;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
}
.themeSwitcher img{
    width: 100%;
    height: 100%;
    object-fit:fill;
}
@keyframes theme_switch {
    0%{
        opacity: 1;
        transform: rotate(0);
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
        transform: rotate(360deg);
    }
}

.head{
    z-index: 102;
    position: relative;
    padding: 5px 20px;
    /* gap: 20px; */
    box-sizing: border-box;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    flex-direction: row;
    max-width: var(--app-desktop-max-width);
}
.logo{
    display: flex;
    user-select: none;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    box-sizing:border-box;
}
.logo img{
    height: 100%;
    object-fit: contain;
}

@keyframes theme_switch_logo {
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.head_content{
    display: flex;
    flex: 1;

}

.nav_link{
    
    padding: 0px 3px;
    margin: 4px;
    text-decoration: none;
    color: #ffffffb7;
    transition: .3s;
    font-family: 'Manrope';
    font-weight: 400;
    border-radius: 3px;
    border: 0px;
    outline: 2px transparent solid;
    
}

.nav_link:hover{
    color: #FFF;
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
}
.links{
    flex: 1;
    flex-direction: row;
    position: relative;
}


.links_expanded{
    min-height: 0px;
    z-index: 10000;
    padding: 20px 20px 50px 25px;
    position: absolute;
    gap: 3px;
    top: 74px;
    left: 0px;
    right: 0px;
    background: var(--navbar-bg-color);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    animation: expand_menu 0.8s cubic-bezier(.05,.05,.05,.8);
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    overflow: hidden;
    border-radius: 0px 0px 15px 15px;
}

.nav_link:focus{
    outline: 2px #ffffff17 solid;
    outline-offset: 4px;
    border-radius: 3px;
    border: 0px;
}
.expand_button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: .5;
    height: 40px;
    gap: 5px;
    padding: 4px 9px;
    width: 55px;
    border: 2px var(--navbar-border-color) solid;
    border-radius: 7px;
    background: none;
    transition: .6s;
    
}
.expand_button_line{
    height: 2px;
    width: 25px;
    background-color: var(--navbar-text-color-menu-item);
    border-radius: 10px;
}
.expand_button:hover{
    opacity: .7;
}

.information_panel{
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    padding: 5px;
}

.info_phone{
    text-decoration: none;
    color: var(--navbar-text-color-menu-item);
    font-family: 'Manrope';
    opacity: 0.9;
    
    margin-right: 10px;
    font-weight: 600;
    transition: .3s;
}

.info_address{
    text-decoration: none;
    color: var(--navbar-text-color-menu-item);
    font-family: 'Manrope';
    opacity: 0.9;
    
    margin-right: 10px;
    font-weight: 400;
    transition: .3s;
}

/* Mobile Min */
@media (max-width: 500px ) {
    .logo{
        height: 60px;
        width: 120px;
        padding: 10px 0px;
    }
    .info_phone{
        font-size: 14px;
    }
    .info_address{
        font-size: 12px;
    }
}

@media (min-width: 501px) {
    .logo{
        height: 60px;
        width: 160px;
    }
    .info_phone{
        font-size: 16px;
    }
    .info_address{
        font-size: 14px;
    }
}

/* Mobile */
@media (max-width: 800px ) {
    .links{
        display: none;
    }
    .links_expanded{
        display: flex;
    }
    .expand_button{
        display: flex;
    }
    .nav_link:hover{
        color: #FFF;
        transform: translateX(2px);
    }
}


/* Desktop */
@media (min-width: 801px ) {
    .links{
        display: flex;
    }
    .links_expanded{
        display: flex;
    }
    .expand_button{
        display: flex;
    }
    
    .nav_link:hover{
        color: #FFF;
        transform: translateY(-2px);
    }
}


@keyframes expand_menu{
    from{
        
        transform-origin: top;
        transform:scaleY(0);
    }
    to{
        transform-origin: top;
        transform: scaleY(1);
    }
}

@keyframes unexpand_menu{
    from{
        transform-origin: top;
        transform:scaleY(1);
    }
    to{
        transform-origin: top;
        transform: scaleY(0);
    }
}

@keyframes show_bg{
    from{
        opacity: 0;
    }
    to{
        opacity: 0.7;
    }
}

@keyframes hide_bg{
    from{
        opacity: 0.7;
    }
    to{
        opacity: 0;
    }
}



@keyframes expand_links{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes unexpand_links{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}

@keyframes expand_line1{
    from{
        transform: translateX(0px) translateY(0px) rotate(0deg);
        transform-origin: left top;
    }
    to{
        transform: translateX(4px) translateY(-1px) rotate(45deg);
        transform-origin: left top;
    }
}
@keyframes unexpand_line1{
    from{
        transform: translateX(4px) translateY(-1px) rotate(45deg);
        transform-origin: left top;
    }
    to{
        transform: translateX(0px) translateY(0px) rotate(0deg);
        transform-origin: left top;
    }
}

@keyframes expand_line2{
    from{
        scale: 1 1;
    }
    to{
        scale: 0 1;
    }
}
@keyframes unexpand_line2{
    from{
        scale: 0 1;
    }
    to{
        scale: 1 1;
    }
}

@keyframes expand_line5{
    from{
        transform: translateX(0px) translateY(0px) rotate(0deg);
        transform-origin: left bottom;
        
    }
    to{
        transform: translateX(4px) translateY(2px) rotate(-45deg);
        transform-origin: left bottom;
        
    }
}
@keyframes unexpand_line3{
    from{
        transform: translateX(4px) translateY(2px) rotate(-45deg);
        transform-origin: left bottom;
    }
    to{
        transform: translateX(0px) translateY(0px) rotate(0deg);
        transform-origin: left bottom;
    }
}

@keyframes button_expand_show{
    from{
        border-color: #ffffff4b;
    }
    to{
        border-color: transparent;
    }
}

@keyframes button_expand_hide{
    from{
        border-color: transparent;
    }
    to{
        border-color: var(--navbar-border-color);
    }
}