.root{
    width:100%;
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
}
.carousel_root{
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    width: 100%;
    flex: auto;
}
.slides{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.9;
}

.carousel_slide{
    position: absolute;
    width:  100%;
    height:  100%;
    
    object-fit: cover;
}

.top_gradient{
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 180px;
    background: var(--navbar-bg-gradient);
}

.carousel_gradient{
    user-select: none;
    bottom: 0px;
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 300px;
    background: rgb(0,0,0);
    border-radius: 25px;
    background: var(--footer-bg-gradient);
}
.schedule_table{
    width: auto;
    margin-left: 20px;
}

.schedule_title{
    color: #AAA;
    font-weight: 700;
    padding-right: 20px;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
}
.schedule_value{
    color: #AAA;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    font-family: Manrope;
}
.schedule_dayoff{
    color: #ffffff;
    font-family: Manrope;
    font-weight: 800;
}
.logo{
    z-index: 3;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    position: absolute;
    user-select: none;
    -webkit-filter: drop-shadow(5px 5px 5px #222);
}
.menu_button{
    z-index: 4;
    left: 50%;
    transform: translate(-50%, 0);
    top: 50%;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    justify-content: center;
}
.menu_url{
    width: auto;
    font-size: 19px;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    font-family: Manrope;
    font-weight: 600;
    border:  solid 3px #FFF ;
    padding: 7px 15px 7px 15px;
    border-radius: 45px;
    text-align: center;
}
.menu_description{
    padding-top: 3px;
    font-family: Manrope;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    text-align: center;
}
.blocks{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 10px;
}

.title{
    padding: 25px 10px 5px 10px;
    margin-left: 20px;
    font-size: 30px;
    color: #FFF;
    font-family: Manrope;
    font-weight: 700;
    text-align: center;
}
.infoblock_title{
    padding: 5px 0px 10px 10px;
    font-size: 23px;
    color:#EEE;
    font-family: Manrope;
    font-weight: 800;
}
.description{
    margin: 10px 20px;
    padding: 0px 20px;
    color: #CCC;
    font-family: Manrope;
    text-align: justify;
    font-size: 16px;
    line-height: normal;
    margin: 0px;
}
.home_root li{
    font-family: Manrope;
    font-size: 16px;
    color: #CCC;
    font-weight: 400;
}
ul {
    list-style: none;
}
.accent{
    font-weight: 900;
    color: #FFF;
}
.infoblock{
    background-color: rgba(255, 255, 255, 0.021);
    padding: 5px 15px 15px 5px;
    border-radius: 10px;
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
    
}
.block_separator{
    background-color: rgba(255, 255, 255, 0.096);
    height: 1px;
    margin: 0px 5px 15px 10px
}


@media (max-width: 1024px){
    .carousel_slide{
        border-radius: 0px;
    }
    .carousel_gradient{
        border-radius: 0px;
    }
}

@media (min-width: 1025px){
    .carousel_slide{
        border-radius: 0px;
    }
    .carousel_gradient{
        border-radius: 0px;
    }
}


@media (max-width: 500px){
    .logo{
        width: 80%;
    }
    
}
@media (min-width: 501px){
    .logo{
        height: 150px;
    }
    
}

@media (max-width: 700px){
    
    .infoblock{
        width: 100%;
        margin: 15px 10px;
    }
}
@media (min-width: 701px) and (max-width: 9000px){
    .infoblock{
        width: 47%;
        margin: 10px 10px;
    }
}
@media (min-width: 901px){
    .infoblock{
        width: 31%;
        margin: 10px 10px;
    }
}