.root{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:  300px;
    height: 180px;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    font-family: 'Manrope';
    /* background-color: rgba(236, 236, 236, 0.904); */
    /* border: 2px rgba(0, 0, 0, 0.555) solid; */
    transition: 0.6s;
    opacity: 0.9;
    box-shadow: 2px 2px 7px 2px #00000036;
    user-select: none;
    outline: 2px #ffffff00 solid;
    outline-offset: 4px;
    background: rgb(255,255,255);
    background: linear-gradient(118deg, rgba(255,255,255,1) -15%, rgb(173, 173, 173) 40%, rgb(177, 177, 177) 73%, rgba(255,255,255,1) 130%);
}
.root:hover{
    opacity: 1;
    transform: scale(1.01);
    background: rgb(255,255,255);
    background: linear-gradient(118deg, rgb(197, 197, 197) -10%, rgb(189, 189, 189) 20%, rgb(189, 189, 189) 33%, rgba(255,255,255,1) 130%);
    outline: 2px #ffffff60 solid;
    outline-offset: 4px;
    
}
.card{
    font-size: 14px;
    letter-spacing: 2px;
    color: #4e4e4e;
    font-weight: 600;
    margin-bottom: 4px;
}
.name{
    color: #333;
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
    vertical-align: middle;
    font-size: 21px;
    letter-spacing: -1px;
    font-weight: 900;
    margin-bottom: 10px;
}

.balance{
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    right: 10px;
    bottom: 10px;
}

.bonuses_title{
    color: #00000075;
    text-align: center;
    min-width: 70px;
    padding-right: 0px;
    font-size: 14px;
    font-weight: 900;
    padding-right: 5px;
}

.bonuses{
    text-align: center;
    min-width: 70px;
    padding: 2px 10px;
    border-radius: 10px;
    background: rgb(121, 40, 26);
    color: #ffffff;
    font-size: 17px;
    font-weight: 900;
}
.bonuses_zero{
    text-align: center;
    min-width: 70px;
    padding: 2px 10px;
    border-radius: 10px;
    background: #0000002f;
    color: #353535;
    font-size: 17px;
    font-weight: 900;
}