.root{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    height: 100%;
    flex: 1;
    user-select: none;
    
    
}

.root h1{
    color: var(--text-color-strong);
    font-size: 102px;
    margin: 0px;
    transition: 1s;
}
.root h1:hover {
    transform: scale(1.2);
}
.root h2{
    color: var(--text-color-strong);
    font-size: 20px;
    margin: 0px;
    transition: 1s;
}