.root{
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 15;
    height: auto;
    max-height: calc(100dvh - (var(--d-head-height) * 3));
    min-height: 250px;
    background-color: var(--body-background);
    /* border: solid 2px #444; */
    border-radius: 15px;
    box-shadow: 2px 2px 13px 2px #00000036;
    top: calc(var(--d-head-height) + 10px);
    animation: show 0.3s ease-out;
    padding: 10px;
    box-sizing: border-box;
}

.root h1{
    margin-top: 5px;
    margin-bottom: 15px;
    text-align: center;
    color: var(--text-color-strong);
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 20px;
}
.empty_message{
    display: flex;
    flex: 1;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.root h2{
    margin: 0;
    text-align: center;
    opacity: 0.5;
    font-weight: 400;
    color: var(--text-color-strong);
    font-family: 'Manrope';
    font-size: 20px;
}
.root h3{
    margin: 0;
    text-align: center;
    opacity: 0.5;
    font-weight: 500;
    color: var(--text-color-strong);
    font-family: 'Manrope';
    font-size: 13px;
}

.background{
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    background-color: #000;
}
.products_list{
    z-index: 100;
    display: block;
    flex-direction: column;
    gap: 4px;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    padding-right: 5px;
}

.products_list::-webkit-scrollbar{
    overflow: overlay;
    display: auto;
    width: 4px;
}
.products_list::-webkit-scrollbar-thumb{
    background-color: var(--app-sb-thmb-color);
  }
  
.products_list::-webkit-scrollbar {
    background: var(--app-sb-back-color);
  }

/* PRODUCT */
.product_root{
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 5px;
    background-color: var(--d-product-bg-color);
    min-height: 80px;
    width: 100%;
    gap: 8px;
}

.product_root img{
    object-fit: cover;
    height: 70px;
    width: 70px;
    border-radius: 5px;
    margin-top: 5px;
    margin-left: 5px;
}

.info{
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 75px;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5px;
    margin-right: 5px;
}
.info p{
    margin-top: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 100%;
    color: var(--text-color);
    flex: 1;
    font-size: 13px;
    font-weight: 600;
    opacity: 0.7;
    font-family: 'Manrope';
}

.cart_product_buttons{
    display: flex;
    align-self: flex-end;
    right: 5px;
    margin-bottom: 5px;
    flex-direction: row;
    align-items: center;
    outline: none;
    /* border: 1px solid var(--d-product-border); */
    border-radius: 7px;
    background-color: transparent;
    transition: .3s;
    user-select: none;
    height: 30px;
    width: 80px;
}
.cart_product_buttons p{
    color: var(--text-color);
    height: 100%;
    padding-top: 6px;
    box-sizing:border-box;
    font-size: 17px;
    margin: 0;
    flex: 1;
    text-align: center;
    font-weight: 700;
}
.cart_product_buttons button{
    color: var(--text-color);
    height: 100%;
    background-color: transparent;
    font-size: 20px;
    border: none;
    outline: none;
    font-weight: 500;
    flex: 1;
}

.bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
}
.bottom button{
    cursor: pointer;
    background-color: transparent;
    height: 35px;
    width: 150px;
    font-family: 'Manrope';
    font-weight: 600;
    color: var(--text-color);
    border: 1px solid var(--d-cat-border);
}

@media (max-width: 740px){
    .root{
        left: 10px;
        right: 10px;
        
    }

    @keyframes show{
        from{
            opacity: 0;
            transform: scale(0.9);
            transform-origin: top;
        }
        to{
            opacity: 1;
        }
    }

    @keyframes hide{
        from{
            opacity: 1;
        }
        to{
            opacity: 0;
            transform: scale(0.9);
            transform-origin: top;
        }
    }
}

@media (min-width: 741px){
    .root{
        width: 350px;
        right: 10px;
    }

    @keyframes show{
        from{
            opacity: 0;
            transform: scale(0.9);
            transform-origin: right;
        }
        to{
            opacity: 1;
        }
    }

    @keyframes hide{
        from{
            opacity: 1;
        }
        to{
            opacity: 0;
            transform: scale(0.9);
            transform-origin: right;
        }
    }
}