.loyalty_root{
    font-family: Manrope;
    color: rgb(196, 196, 196);
    height: 100%;
    padding: 0px 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}
.content{
    width: 100%;
    position: relative;
    max-width: 700px;
    
}
.rest_logo_list{
    margin: 10px 0px;
    opacity: 0.4;
    display: flex;
    gap: 35px;
    justify-content: center;
}
.rest_logo{
    height: 60px;
}
.loyalty_root p{
    font-size: 16px;
    text-align: justify;
    padding-top: 9px;
    color: var(--text-color);
}
.strong_text{
    padding: 0px 3px;
    font-weight: 800;
    font-size: 20px;
    color:var(--text-color-strong);
}

.title{
    color: var(--text-color);
    text-align: center;
    font-family: Manrope;
    font-weight: 800;
    font-size: 26px;
    margin-bottom: 15px;
    margin-left: 0px;
    
}
.per{
    font-family: Manrope;
    padding:'0px 5px';
    font-size:'20px'; 
    font-weight:'800';
}

.reg_link_root{
    display: flex;
    align-items: center;
}
.reg_link {
    user-select: none;
    display: block;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    padding: 7px 13px;
    margin: 20px auto;
    color: var(--text-color);
    font-weight: 800;
    text-decoration: none;
    border: 2px #b3b3b3 solid;
    border-radius: 40px;
    transition: 0.3s;
  }
  
  .reg_link:hover{
    border: 2px var(--app-accent-color) solid;
    border-radius: 0px;
    padding: 7px 26px;
    color: var(--text-color-strong);
  }

  .cardimg {
    box-shadow: 4px 4px 8px 2px rgba(0, 0, 0, 0.2);
    height: 160px;
    border-radius: 12px;
    display: block;
    margin: 30px auto 30px auto;
  }
