.anim{
    animation: ShowSlide 5s ease-out;
}

@keyframes ShowSlide{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}