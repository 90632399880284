:root {
  --app-desktop-max-width: 1440px;
  
  --app-bg-color: rgb(10, 10, 10);
  --app-navbar-color: rgba(0, 0, 0, 0);
  --app-footer-color: rgba(17, 17, 17, 0);
  --app-textcolor-title: #ffffff;
  --app-textcolor-normal: #cacaca;
  --d-head-height: 60px;

  --d-product-gradient-v: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0.7) 100%);
  --d-product-gradient-h: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0.6) 100%);
}

html[data-theme='dark']{
  --app-accent-color: rgb(175, 23, 69);
  --body-background: #222;
  --app-sb-back-color: #222;
  --app-sb-thmb-color: #444;
  /* --body-background: linear-gradient(125deg, rgba(10,10,10)0%, rgba(15,15,18)20%, rgba(30, 30, 35) 40%, rgba(60, 60, 65) 100%); */
  --theme-sw-bg: #CCC;
  --theme-sw-border: #444;
  --navbar-bg-color: #000;
  --navbar-bg-gradient: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgb(0, 0, 0, 0.6) 50%, rgb(0, 0, 0, 0.8) 100%);
  --navbar-bg-under: #000000be;
  --navbar-expanded-bg-color: #000;
  --navbar-text-color-menu-item: #FFF;
  --navbar-border-color: rgba(255, 255, 255, 0.2);
  --text-color: #cfcfcf;
  --text-color-placeholder: #616161;
  --text-color-strong: #FFF;
  --footer-bg-gradient: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgb(0, 0, 0, 0.6) 50%, rgb(0, 0, 0, 0.8) 100%);

  --d-product-bg-color: #272727;
  --d-product-bg-color-hover: #2b2b2b;
  --d-product-border: #444;
  --d-product-img-bg: #333;
  --d-product-name-color: #EEE;
  --d-product-shadow-color: #5e5e5e38;

  --d-cb-bg-color: #313131;
  --d-cat-border: #c4c4c4;
  --d-prod-btn-bg: #ffffff1a;
  --d-prod-bg: #ffffff0e;
  --d-cat-head-color: #222;
  --d-radio-enabled-bg-color: #b6b6b6;
  --d-radio-enabled-text-color: #141414;
}

html[data-theme='light']{
  --app-accent-color: rgb(218, 48, 99);
  --body-background: #ffffff;
  --app-sb-back-color: #ffffff;
  --app-sb-thmb-color: #a1a1a1;
  /* --body-background: linear-gradient(125deg, rgb(255, 255, 255)0%, rgb(211, 211, 211)20%, rgb(192, 192, 192) 40%, rgb(138, 138, 138) 100%); */
  --theme-sw-bg: #444;
  --theme-sw-border: #CCC;
  --navbar-bg-color: #FFF;
  --navbar-bg-gradient: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.5) 100%);
  --navbar-bg-under: #000000b6;
  --navbar-expanded-bg-color: #e7e7e7;
  --navbar-text-color-menu-item: #000000;
  --navbar-border-color: rgba(0, 0, 0, 0.2);
  --text-color: #272727;
  --text-color-strong: #000;
  --text-color-placeholder: #919191;
  --footer-bg-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.6) 100%);

  --d-product-bg-color: #f1f1f1;
  --d-product-bg-color-hover: #e4e4e4;
  --d-product-border: #CCC;
  --d-product-img-bg: #EEE;
  --d-product-name-color: #333;
  --d-product-shadow-color: #0000001c;

  --d-cb-bg-color: #f1f1f1;
  --d-cat-border: #3f3f3f;
  --d-prod-btn-bg: #0000001a;
  --d-prod-bg: #0000001a;
  --d-cat-head-color: #FFF;
  --d-radio-enabled-bg-color: #494949;
  --d-radio-enabled-text-color: #ececec;
}


html{
  scrollbar-gutter: stable;
}

body {
  transition: .3s;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--app-bg-color); */
  /* background: linear-gradient(135deg, rgba(10,10,10)0vh, rgba(15,15,18)30vh, rgba(30,30,35)80vh, rgba(30,30,35) 120vh); */
  background: var(--body-background);
  overflow: auto;
  
}

body::-webkit-scrollbar{
  overflow: overlay;
  display: auto;
  width: 5px;
}
body::-webkit-scrollbar-thumb{
  background-color: var(--app-sb-thmb-color);
  transition: 0.3s;
}

body::-webkit-scrollbar {
  background: var(--app-sb-back-color);
  transition: 0.3s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button{
  padding: 0px;
}

*{
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
}

