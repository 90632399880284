.root{
    z-index: 200;
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100dvw;
    height: 100%;
    bottom: 0px;
    top: 0px;
    background-color: #000000d3;
    animation: bg_show .3s ease-out ;
}

.card_root{
    margin: 20px 0px;
}
.button_google_pay{
    cursor: pointer;
    margin: 10px 0px;
    height: 50px;
    border-radius: 7px;
    outline: 2px transparent solid;
    outline-offset: 3px;
    transition: 0.3s;
}
.loading_google_pay{
    
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: center;
    height: 50px;
    margin: 10px 0px;
}
.loading_google_pay p{
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 4px;
    color: #FFF;
    font-family: 'Manrope';
    font-weight: 600;
}

.button_apple_wallet{
    cursor: pointer;
    margin: 10px 0px;
    height: 60px;
    outline: 2px transparent solid;
    outline-offset: 3px;
    border-radius: 13px;
    transition: 0.3s;
}
.button_apple_wallet:hover, .button_google_pay:hover{
    outline: 2px #ffffff46 solid;
    outline-offset: 3px;
}

.list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.list h2{
    padding: 0px 10px;
    width: 100%;
    font-size: 12px;
    font-family: 'Manrope';
    font-weight: 600;
    color: #ffffffad;
}


@media (max-width:450px){
    .content{
        border-radius: 25px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;
        background: #181818;
        animation: show .3s ease-out;
        
    }
}
@media (min-width:451px){
    .content{
        border-radius: 25px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 450px;
        height: auto;
        background: #181818;
        animation: show .3s ease-out;
    }
}

.button_registrate{
    background-color: #812222;
    font-size: 15px;
    color: #FFF;
    font-family: 'Manrope';
    font-weight: 700;
    border-radius: 35px;
    outline: none;
    border: none;
    padding: 10px 20px;
    transition: 0.3s;
    outline: 2px transparent solid;
    outline-offset: 2px;
}
.button_registrate:focus{
    outline: 2px #ffffff46 solid;
    outline-offset: 2px;
}
.button_registrate:hover{
    outline: 2px #ffffff46 solid;
    outline-offset: 2px;
}

.back_button{
    background-color: #ffffff00;
    border: none;
    display: flex;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    transition: .5s;
    outline: none;
    border: none;
    height: 40px;
    width: 40px;
    place-self: flex-start;
}
.back_button:hover{
    background-color: #ffffff09;
    outline: none;
    border: none;
}
.back_button:focus{
    background-color: #ffffff02;
    outline: 3px #ffffff18 solid;
    outline-offset: 2px;
    border: none;
}
.back_button img{
    margin-top: 2px;
    opacity: 0.4;
    width: 20px;
    height: 17px;
}


/* ANIMTAIONS */

@keyframes show {
    from{
        transform: scale(0.9);        
        opacity: 0.5;
    }
    to{
        transform: scale(1);        
        opacity: 1;
    }
}


@keyframes bg_show {
    from{
        background-color: transparent;
    }
    to{
        background-color: #000000d3;
    }
}

@keyframes hide {
    from{
        transform: scale(1);   
        opacity: 1;
    }
    to{
        opacity: 0;
        transform: scale(0.9); 
             
    }
}

@keyframes bg_hide {
    from{
        background-color: #000000d3;
    }
    to{
        background-color: transparent;
        
    }
}