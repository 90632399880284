.root{
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
    padding: 15px 0;
    align-items: flex-end;
    margin-right: 20px;
}
.root_anim{
    opacity: 0;
    animation: expand_links .5s ease-out;
    animation-delay: .3s;
    animation-fill-mode: forwards;
    transition: transform  .3s ease-out;
}
.root_anim:hover .text{
    opacity: 1;
    transform: translateX(-10px);
    font-weight: 800;
}
.icon_container{
    display: flex;
    position: relative ;
    width: 30px;
    height: 30px;
}
.icon{
    height: 25px;
    width: 25px;
    object-fit: contain;
    position: absolute;
    opacity: 1;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: auto;
    transition: .3s;
}
.root:hover .icon{
    opacity: 0;
}

.icon_hover{
    height: 25px;
    width: 25px;
    object-fit: contain;
    position: absolute;
    opacity: 0;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: auto;
    transition: .3s;
}

.text{
    color: var(--navbar-text-color-menu-item);
    text-transform: uppercase;
    padding-left: 24px;
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 700;
    opacity: 1;
    transition: .3s;
}
.root:hover .text{
    opacity: 1;
    
}
.separator{
    margin-left: 10px;
    width: 150px;
    height: 1px;
    background-color:var(--navbar-text-color-menu-item);
    opacity: 0.3;
    margin-top: 30px;
}

@keyframes expand_links{
    from{
        opacity: 0;
        transform: translateX(-30px);
    }
    to{
        opacity: 1;
        transform: translateX(0px);
    }
}