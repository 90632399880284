.per_inf{
    max-width: var(--app-desktop-max-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.per_inf p{
    padding: 0px;
    margin: 10px 0px;
    color: var(--text-color);
    font-size: 12px;
    text-align: justify;
}   
.per_inf li{
    font-size: 11px;
    color: var(--text-color);
}


@media (max-width: 1024px ) {
    .per_inf{
        margin:15px 25px;
    }
}
@media (min-width: 1023px ) {
    .per_inf{
        margin:15px auto;
    }
}