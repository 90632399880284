.root{
    z-index: 10;
    position: absolute;
    display: flex;
    left: 0px;
    right: 0px;
    top: var(--d-head-height);
    margin: 0 auto;
    max-width: var(--app-desktop-max-width);
    background-color: var(--navbar-bg-color);
    border-radius: 0px 0px 25px 25px;
    padding: 5px 20px;
    animation: expand_menu 0.3s cubic-bezier(.05,.05,.05,.8);
    
}

.categories_list{
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)) ;
}
.category_root{
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
    min-height: 30px;
}

.category_title{
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
    padding: 7px 7px;
    font-size: 13px;
    color: var(--text-color-strong);
    font-weight: 500;
}

.subcategories_root{

}

.subcategory_title{
    cursor: pointer;
    /* text-transform: uppercase; */
    padding: 6px 3px;
    margin-left: 15px;
    font-size: 15px;
    color: var(--text-color);
    opacity: 0.8;
}

@keyframes expand_menu{
    from{
        
        transform-origin: top;
        transform:scaleY(0);
    }
    to{
        transform-origin: top;
        transform: scaleY(1);
    }
}

@keyframes unexpand_menu{
    from{
        transform-origin: top;
        transform:scaleY(1);
    }
    to{
        transform-origin: top;
        transform: scaleY(0);
    }
}

