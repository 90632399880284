.loading_message{
    display: flex;
    color: var(--text-color);
    width: 100%;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-weight: 600;
    font-size: 25px;
}

.root{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: var(--app-desktop-max-width);
    margin:  0 auto;
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0.116); */
}



.desktop_categories_root{
    position: sticky;
    top: -1px;
    width: 280px;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: var(--d-head-height);
    box-sizing: border-box;
    z-index: 3;
}

.desktop_category{
    user-select: none;
    color: var(--text-color);
    cursor: pointer;
    text-transform: uppercase;
    padding: 7px;
    padding-left: 10px;
    margin: 5px 0px;
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    box-sizing :border-box;
    transition: 0.4s ease-in-out;
    opacity: 0.7;
}
.desktop_category_sticky{
    
    font-weight: 800;
    opacity: 1;
    font-size: 16px;
    color: var(--text-color-strong);
    box-shadow: 0px 0px 0px 1px var(--text-color);
}

.head_root{
    z-index: 3;
    position: sticky;
    top: -2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: var(--d-head-height);
    width: 100%;
    max-width: var(--app-desktop-max-width);
    padding: 0px 10px;
    box-sizing: border-box;
    transition: 0.3s;
}
.head_root_sticky{
    background-color: var(--body-background);
    box-shadow: 0px 14px 15px -15px #00000093;
}
/* .head_root_sticky .expand_categories{
    position: fixed;
    top: 5px;
    z-index: 100;
} */

.title{
    margin-left: 30px;
    margin-top: 5px;
    font-size: 30px;
    font-family: 'Manrope';
    font-weight: 800;
    color: var(--text-color-strong);
}

.list_container{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.category_title_root{
    max-lines: 1;
    white-space: nowrap;
    overflow: hidden;
    transition: font-size .3s;
    display: flex;
    align-items: center;
    position: sticky;
    top: -2px;
    margin: 0px 0px 30px 0px;
    overflow-x: hidden;
}

.category_title_root{

}
.category_title_root_sticky{

    height: var(--d-head-height);
    text-overflow: ellipsis;
}

.category_title_root_sticky .category_title{
    font-size: 14px;
    border: none; 
    margin: 10px 10px;
}

.category_title{
    text-transform: uppercase;
    /* transition: font-size .3s; */
    border: 1px var(--d-cat-border) solid;
    padding: 4px 12px;
    color: var(--text-color-strong);
    font-size: 20px;
    font-family: 'Manrope';
    font-weight: 800;
    margin: 10px 30px;
}
.subcategory_title{
    
    opacity: 1;
    color: var(--text-color);
    font-size: 17px;
    font-family: 'Manrope';
    font-weight: 600;
    margin: 15px 42px;
}
.category_container{
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 40px;
}
.products_container{
    
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.product_card{
    margin: 3px;
}

.head_categories{
    z-index: 11;
    background: var(--d-cb-bg-color);
    height: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: var(--text-color-strong);
    font-size: 14px;
    font-family: 'Manrope';
    font-weight: 700;
    user-select: none;
    cursor: pointer;
    padding: 5px 15px;
    /* border: solid var(--d-cat-border) 1px; */
    border-radius: 20px;
    /* transition: .3s; */
}

.head_center{
    flex: 1;
}

.head_cart{
    z-index: 11;
    background: var(--app-accent-color);
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #FFF;
    font-size: 16px;
    min-width: 50px;
    font-family: 'Manrope';
    font-weight: 800;
    user-select: none;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 20px;
}

.menu_expand_head_bg{
    z-index: 10;
    display: flex;
    position: fixed;
    margin: 0 auto;
    max-width: var(--app-desktop-max-width);
    top: 0px;
    left: 0px;
    right: 0px;
    height: 135px;
    background-color: var(--navbar-bg-color);
    /* animation: expand_bg_anim 0.4s cubic-bezier(.05,.05,.05,.8) ; */
}

.expand_bg{
    z-index: 9;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 100lvh;
    /* opacity: 0.9; */
    background-color: var(--navbar-bg-under);
    animation: show_bg .3s ease-out;
    animation-fill-mode: forwards;
}

@keyframes show_bg{
    from{
        opacity: 0;
        backdrop-filter: blur(0px);
    }
    to{
        opacity: 1;
        backdrop-filter: blur(12px);
    }
}

@keyframes hide_bg{
    from{
        opacity: 1;
        backdrop-filter: blur(12px);
    }
    to{
        backdrop-filter: blur(0px);
        opacity: 0;
    }
}



@media (max-width: 399px){
    .desktop_categories_root{
        display: none;
    }
    .products_container{
        gap: 5px;
        padding: 5px;
    }
    .category_title_root_sticky{
        margin: 0px 0px 30px 75px;
    }
}

@media (min-width: 400px) and (max-width: 740px){
    .desktop_categories_root{
        display: none;
    }
    
    .products_container{
        gap: 5px;
        padding: 5px;
    }
    .category_title_root_sticky{
        margin: 0px 0px 30px 75px;
    }
}

@media (min-width: 741px){
    .head_categories{
        display: none;
    }
    .products_container{
        gap: 15px;
        padding: 10px;
    }
    .category_title_root_sticky{
        margin: 0px 0px 30px 15px;
    }
}




@keyframes expand_bg_anim{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes unexpand_bg_anim{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}



